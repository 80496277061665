import React from 'react';
import { useSelector } from 'react-redux';
import AnswerWeb from './AnswerWeb';
import AnswerMobile from './AnswerMobile';
import AnswerLong from './AnswerLong';

import styles from './Answer.module.sass';

export default function Answer() {
  const { data, loading, error } = useSelector(state => state.ask);
  let dataJson, answer, parentResults, parentAnswerField;

  if (loading === 'pending') {
    answer = 'Loading...';
  }

  if (loading === 'idle') {
    answer = data;
    if (answer && answer.length !== 0) {
      dataJson = answer;
      parentAnswerField = dataJson.answer_field;
      parentResults = dataJson.results.filter(result => result[parentAnswerField] !== '');
    }
  }

  if (error !== null) {
    answer = <>{error}</>;
  }

  const isParagraphDisplay = [
    'warnings',
    'contraindications',
    'dosage_information',
    'indications',
    'main_patent_expiry',
    'review_type'
  ].includes(parentAnswerField);

  function createAnchorTags(links) {
    let urls = links.split(',');
    let anchorTags = urls.map(url => {
      url = url.trim(); 
      return `<a href="${url}">${url}</a>`;
    });
    return anchorTags.join('');
  }
  
  const isClinicalTrials = parentAnswerField === 'clinical_trials'
/*   if (isClinicalTrials) {
    parentResults = parentResults.map(result => {
      let resultCopy = { ...result };
      resultCopy.clinical_trials = createAnchorTags(resultCopy.clinical_trials);
      return resultCopy;
    });
  } */
  
  const isBigScreen = window.innerWidth > 600;
  const answerdFieldAtFirst = (a, b) => (parentAnswerField === a) ? -1 : (parentAnswerField === b) && 1;
  const answerFieldAtLast = (a, b) => (parentAnswerField === a) ? 1 : (parentAnswerField === b) && -1;
  const getTitle = (nResults) => (nResults === 1) ? 'Answer' : (nResults === 0) ? 'We could not find a match for your query. Please try again.' : 'Possible Answers'
  const getClassName = (nResults) => (nResults === 0) ? styles.noresults_title : ''
  console.log(parentResults);
  console.log(isClinicalTrials);

  return (
    <div>
      {isParagraphDisplay
        ? <AnswerLong parentResults={parentResults} getClassName={getClassName} getTitle={getTitle} answerFieldAtLast={answerFieldAtLast} isClinicalTrial={isClinicalTrials}/>
        : isBigScreen
          ? <AnswerWeb parentResults={parentResults} getClassName={getClassName} getTitle={getTitle} answerdFieldAtFirst={answerdFieldAtFirst} isClinicalTrial ={isClinicalTrials}/>
          : <AnswerMobile parentResults={parentResults} getClassName={getClassName} getTitle={getTitle} answerdFieldAtFirst={answerdFieldAtFirst} isClinicalTrial ={isClinicalTrials}/>
      }

    </div>
  );
}
