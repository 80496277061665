import React from 'react';
import styles from '../answer/Answer.module.sass';

export default function createAnchorTags(links) {
    let urls = links.split(',');
    let anchorTags = urls.map((url, index) => {
      url = url.trim();
      return (
        <React.Fragment key={index}>
          <a href={url} className={styles.clinicalTrialLink}>{url}</a>
          <br />
        </React.Fragment>
      );
    });
    return anchorTags;
  }